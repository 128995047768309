import { http } from './config'
import authHeader from './auth-header';

export default {


    

    total_carne_entregue: (dataI, dataF, nomeEmpresa) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
        }
        return http.get('/faturamento-dash/total-carne-entregue/', {
           params: params,
           headers: authHeader()   
        })
    },

    total_carne_nc: (dataI, dataF, nomeEmpresa) => {
        
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
        }

        return http.get('/faturamento-dash/total-carne-nc/', {
           params: params,
           headers: authHeader() 
              
        })
    },

    
    

   

}
