import { render, staticRenderFns } from "./Faturamento.vue?vue&type=template&id=3db2ca55&scoped=true&"
import script from "./Faturamento.vue?vue&type=script&lang=js&"
export * from "./Faturamento.vue?vue&type=script&lang=js&"
import style0 from "./Faturamento.vue?vue&type=style&index=0&id=3db2ca55&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3db2ca55",
  null
  
)

export default component.exports