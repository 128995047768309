<template>
 
  <v-app style="background-color:#f8f9fb" >

  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a 365 dias.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <v-container >
    <v-col cols="12" >
      <filtro-component :opcoes="opcoes" :modos="modos" :tipos="tipos" :calendar="'ano'" :produtos="tipos_funerarios"
      @filtrar="filterData" :title_opcao="'Situação'"
        ></filtro-component>
    </v-col>
  </v-container>

 



  <v-container style="background-color:#eee"  
    id="contrato"
    fluid
    tag="section">
    
    <v-row>

        <v-col
        cols="12"
        sm="6"
        lg="4">

        <base-material-stats-card elevation="1"
              color="green"
              icon="mdi-barcode-scan"
              subIcon="mdi-information"
              subIconColor="blue"
              title="Carnês Entregues"
              sub-icon-color="black"
              :valuex="this.bool_01"
              :value="this.entregue"

              :sub-text="'Total de Carnês Entregues'"

              />
        </v-col>

        <v-col
        cols="12"
        sm="6"
        lg="4">

        <base-material-stats-card elevation="1"
              color="red"
              icon="mdi-barcode-scan"
              subIcon="mdi-information"
              subIconColor="blue"
              title="Clientes Não Localizados"
              sub-icon-color="black"
              :valuex="this.bool_02"
              :value="this.nc"

              :sub-text="'Total de Clientes Não Localizados'"

              />
        
        </v-col>

      
    </v-row>




  </v-container>

</v-app> 
</template>

<script>
import Faturamento from '../../services/faturamento'
import FiltroComponent from "./components/Filtro.vue"; // Certifique-se de que o caminho esteja correto


  export default {

  name: 'Faturamento',
  
  components: {
    FiltroComponent,
  },
  
  created() {
      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const year = currentDate.getFullYear();
      this.selectedStartDate = `${"01"}/${"01"}/${year}`;
      this.selectedEndDate =  this.formatDateBR(new Date())
     
    },
    
  mounted() {

    this.dashboard();
   
    
  },
 
    data () {
      return {
       
        rules: {
            required: value => !!value || 'Obrigatório.',
          },

          empresa: 'Todas',
          selectedCompany: "Todas",
          selectedType: "Todos",
          selectedOpcao: "",
          selectMode: "",
          selectedStartDate : "",
          selectedEndDate : "",
          isLoading: false,
          opcoes: [],
          modos: ["Cadastro"],
          showFilter: false,
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "Todas"],
          tipos: ["Todos", "Planos", "Jazigo", "Cremação", "Funerária", "Serviços Administrativos", "Serviços Cemiteriais"],

          bool_01:true,
          bool_02:true,
          showProgress: false,



          tipos_funerarios: [
                "Todos",
              ],
        

          entregue: 0,
          nc: 0,

      }
  },

    methods: {


      getFirstDayOfYear() {
          const dataAtual = new Date();
          const primeiroDiaDoAno = new Date(dataAtual.getFullYear(), 0, 1); // Janeiro é representado como 0
          return this.formatDateBR(primeiroDiaDoAno);
        },

      formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },


       filterData(valores) {

        this.isLoading = true;
         let nova_consulta = false;

        
       
          if (this.selectedStartDate !== valores.selectedStartDate || this.selectedEndDate !== valores.selectedEndDate
          || this.selectedCompany !== valores.selectedCompany
          || this.selectedType !== valores.selectedType) {
            nova_consulta = true;
          }

          this.selectedType = valores.selectedType === "Jazigo" ? "Cemitérios" : valores.selectedType;
          this.selectedOpcao = valores.selectedOpcao;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.selectMode = valores.selectMode;



          this.selectedStartDate = valores.selectedStartDate;
          this.selectedEndDate = valores.selectedEndDate;


        if(nova_consulta){
           
          this.total_cobranca_reneg_volume = 0,
          this.total_cobranca_reneg_cancelado = 0,
          this.dashboard();

        }else{
            console.log('Valor'," Recerregar Dados");
            
        }

      },


      formatarMoeda(valor) {
        // Verifica se o valor é numérico
        if (isNaN(valor)) {
            return 'Valor inválido';
        }

        // Formata o valor como moeda brasileira (BRL)
        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
    },

     
    calcularDiferencaEntreDatas(dataInicial, dataFinal) {
                const data1 = new Date(dataInicial);
                const data2 = new Date(dataFinal);

                // Verifique se as datas são válidas
                if (isNaN(data1) || isNaN(data2)) {
                    return NaN; // Retorna NaN se uma das datas for inválida
                }

                const diferencaEmMilissegundos = data2 - data1;
                const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

                return diferencaEmDias;
      },

      

      dashboard() {
 
              const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
              const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
              const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

                      if (diferencaEmDias > 365 || diferencaEmDias < 1) {

                        this.isLoading = false;
                        this.showFilter = true;
                        this.showProgress = false;
                        return 
                      }


                    Faturamento.total_carne_entregue( converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany, this.selectedType ).then(response => {
                      if(response.status === 200){
                      
                         this.entregue = response.data;
                      } 
                    }).catch(e => {    
                    }).finally(() => {
                      this.bool_01 = false
                    })  

                    Faturamento.total_carne_nc( converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany, this.selectedType).then(response => {
                      if(response.status === 200){
                         this.nc = response.data;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                      this.bool_02 = false
                    })  





                    

      }

    },
  }

  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }


</script>

<style scoped>
  .v-progress-circular {
    margin: 1rem;
  }

  .custom-gray-background {
    height: 80px;
  }

  
  
  /* Defina uma classe para preencher a largura horizontalmente e centralizar verticalmente */
  .fill-width {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
  }
  
  /* Centralize horizontalmente */
  .justify-center {
    justify-content: center;
  }

  .card-loader {
    height: 100%; /* Define a altura do content-loader como 100% para preencher o v-card */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  </style>
